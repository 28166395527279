import { connect } from 'react-redux';
import ListPendaftaranPPDB from '../../views/Student/ListPendaftaranPPDB.student.view';
import { getParamOptionsAcademics, getStudentOptions, getYearsOfEducationsOptions } from '../../states/thunks/options.thunk';
import { listPPDBOffline, listStudentsPPDBF07, listStudentsPPDB, 
  listStudentsClassDivGeneral, listStudentsPPDBRicci } from '../../states/thunks/academics.thunk';

function mapStateToProps(state) {
    return {
      result: state.result,
      user: state.user || {},
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
      getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
      getYearsOfEducationsOptions: (payload) => dispatch(getYearsOfEducationsOptions(payload)),
      handleListPPDBOffline: (payload, goback) => dispatch(listPPDBOffline(payload, goback)),
      handleListStudentsPPDBF07: (payload, goback) => dispatch(listStudentsPPDBF07(payload, goback)),
      handleListStudentsPPDB: (payload, goback) => dispatch(listStudentsPPDB(payload, goback)),
      handleListStudentsClassDivGeneral:(payload, goback) => dispatch(listStudentsClassDivGeneral(payload, goback)),
      handleListStudentsPPDBRicci:(payload, goback) => dispatch(listStudentsPPDBRicci(payload, goback)),
   
    };
  }

  export default connect(mapStateToProps, mapDispatchToProps)(ListPendaftaranPPDB);
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map } from "lodash";
import { Button } from "../../components/base/index";
import * as commonActions from "../../states/actions/common.action";
import { validateRequiredFields } from "../../../src/utils/validation.util";

import PPDBAlamatForm from "./components/PPDBAlamatForm.component";
import PPDBOrtuForm from "./components/PPDBOrtuForm.component";
import PPDBOrtuIbuForm from "./components/PPDBOrtuIbuForm.component";
import PPDBOrtuWaliForm from "./components/PPDBOrtuWaliForm.component";
import PPDBDataDiri from "./components/PPDBDataDiri.component";

export default class ManageRegistrationPPDB extends PureComponent {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this._setForm = this._setForm.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this._onClickBack = this._onClickBack.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getClassesOptions = this._getClassesOptions.bind(this);
    this.onRemoveFile = this.onRemoveFile.bind(this);
    this._getYearsOfEducation = this._getYearsOfEducation.bind(this);
    this._getPrmLevels = this._getPrmLevels.bind(this);

    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    this.state = {
      page: 1,
      form: {
        value: {
          levels_id,
          units_id: school_unit.id,
          organizations_id: organizations_id,
          va_code: school_unit.va_code,
        },
        error: {},
      },
      param: {},
    };
  }

  
  componentDidMount() {
    const {
      handleGetCitizenships,
      handleGetReligions,
      handleGetEducations,
      handleGetOccupations,
      handleGetClasses,
      handleGetGenders,
      handleGetBloodTypes,
      handleGetLevels,
      location,
      user,
    } = this.props;
    const { state = {} } = location;
    const { school_unit, organizations_id } = user;
    const { levels_id, units_id } = school_unit;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    const { content } = this.state;
    
    const paramTypes = [
      "educations",
      "occupations",
      "citizenships",
      "religions",
      "genders",
      "blood_types",
      "classes",
    ];
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if (isEdit && id) {
      this.setState({ id: id });
      this._setForm(data);
    } else {
      this._getClassesOptions({
        filters: { levels_id: [levels_id], units_id: [school_unit.id] },
      });
    }
    // commonActions.setLoading({ show: true });
    this._getYearsOfEducation({units_id: school_unit.id});
    this._getPrmLevels({
      filters: { units_id: [school_unit.id], organizations_id: organizations_id },
    });

    handleGetCitizenships();
    handleGetReligions();
    handleGetEducations();
    handleGetOccupations();
    handleGetClasses();
    handleGetGenders();
    handleGetBloodTypes();
    handleGetLevels();
  }

  validateEmail(email) {
    if (email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/))
    {
      console.log("test")

      return (true)
    }
    return <>
    {email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)?'':<p>Alamat email tidak valid!</p>}
    </>    
  }


  _onFormChange(event) {
    const { name, value, dataset, checked, type, files } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    const { form, error } = this.state;
    let keyword = null;
    let isValid = true;
    let newError = { ...error };

    if (name === "email" && value !== ""){
      isValid = this.validateEmail(value);
      if(isValid === true) {
        delete newError[name];
      }
      else {
        newError[name] = isValid;
      }
    }

    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;
        if (type === "file") {
          formattedValue = files[0];
        }
        if (inputType === "number") {
          // formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;

          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
              ...newError
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getClassesOptions({ filters: { levels_id: [value] } });
        }
      }
    );
  }

  async _setForm(data) {
    console.log(data.user_id)
    const { handleGetManageRegistration, user } = this.props;
    const { organizations_id, school_unit } = user;
    try {
      const { levels_id } = data;
      this._getClassesOptions({ filters: { levels_id: [levels_id] } });
      this.setState({
        form: {
          value: {
            id: data.id,
            user_id: data.user_id,
            organizations_id: organizations_id,
            units_id: school_unit.id,            
            levels_id: data.levels_id,
            // entrance_year: data.entrance_year,
            year: data.year,
            classes_id: data.classes_id,

            nis: data.nis,
            nisn: data.nisn,
            fullname: data.fullname,
            nickname: data.nickname,
            // upload_image: data.upload_image,
            gender_id: data.gender_id,
            // entrance_date: data.entrance_date,
            height: data.height,
            weight: data.weight,
            pob: data.pob,
            dob: data.dob,
            blood_type_id: data.blood_type_id,
            religion_id: data.religion_id,
            citizen_id: data.citizen_id,
            email: data.email,
            hp: data.hp,

            address: data.address,
            rt: data.rt,
            rw: data.rw,
            province: data.province,
            sub_province: data.sub_province,
            district: data.district,
            sub_district: data.sub_district,

            father_name: data.father_name,
            father_nik: data.father_nik,
            father_religions_id: data.father_religions_id,
            father_citizenships_id: data.father_citizenships_id,
            father_educations_id: data.father_educations_id,
            father_pob: data.father_pob,
            father_dob: data.father_dob,
            father_phone: data.father_phone,
            father_occupations_id: data.father_occupations_id,
            father_salary: data.father_salary,
            father_address: data.father_address,
            father_status: data.father_status,

            mother_name: data.mother_name,
            mother_nik: data.mother_nik,
            mother_religions_id: data.mother_religions_id,
            mother_citizenships_id: data.mother_citizenships_id,
            mother_educations_id: data.mother_educations_id,
            mother_pob: data.mother_pob,
            mother_dob: data.mother_dob,
            mother_phone: data.mother_phone,
            mother_occupations_id: data.mother_occupations_id,
            mother_salary: data.mother_salary,
            mother_address: data.mother_address,
            mother_status: data.mother_status,

            guardian_name: data.guardian_name,
            guardian_nik: data.guardian_nik,
            guardian_religions_id: data.guardian_religions_id,
            guardian_citizenships_id: data.guardian_citizenships_id,
            guardian_educations_id: data.guardian_educations_id,
            guardian_pob: data.guardian_pob,
            guardian_dob: data.guardian_dob,
            guardian_phone: data.guardian_phone,
            guardian_occupations_id: data.guardian_occupations_id,
            guardian_salary: data.guardian_salary,
            guardian_address: data.guardian_address,
            guardian_status: data.guardian_status,
          },
          error: {
            // details: [],
          },
        },
      });
    } catch (err) {
      // err action
    } finally {
      commonActions.setLoading({ show: false });
    }
  }

  async _getYearsOfEducation(filters = {}) {
    const { getYearsOfEducationOptions, user, location } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const { state = {} } = location;
    const { isEdit = false } = state;

    const res = await getYearsOfEducationOptions(filters);

    if (!isEdit) {
      map(res, (data, idx) => {
        var date = new Date();
        var start_date = new Date(data.attributes.start_date)
        if (date > start_date) {
          // tahun ajaran selanjutnya
          if (res[idx+1]) {
            this.setState(prevState => ({
              ...prevState,
              form: {
                ...prevState.form,
                value : {
                  ...prevState.form.value,
                  entrance_year: res[idx+1].value,
                  year: res[idx+1].value.toString().substring(2)
                }
              },
            }))
          }
        }
      });
    }

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        years_educations: res,
      },
    }));
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id } = user;
    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getClassesOptions(filters = {}) {
    const { getClassesOptions, user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    filters.levels_id = levels_id;
    filters.units_id = school_unit.id;
    const res = await getClassesOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        classes: res,
      },
    }));
  }

  async _getPrmLevels(filters = {}) {
    const { getPrmLevels, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPrmLevels(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        levels: res,
      },
    }));
  }

  _onClickNext() {
    const { form } = this.state;
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false } = state;

    let error = validateRequiredFields(form.value, [
      "nisn",
      "fullname",
      "gender_id",
      // "entrance_date",
      "pob",
      "dob",
      "religion_id",
      "address",
    ]);

    if (!isEdit) {
      error = { ...error };
    }

    if (error.nisn == undefined && form.value.nisn.length != 10) {
      error.nisn = "NISN harus 10 digit";
    }

    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error: error,
      },
    }));

    if (isEmpty(error)) {
      window.scrollTo(0, 0);
      this.setState((prevState) => ({
        page: prevState.page + 1,
      }));
    }
  }

  _onClickBack() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page - 1,
    }));
  }

  onRemoveFile() {
    this.setState((prevState) => {
      return {
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            upload_image: "",
            file: "",
          },
        },
      };
    });
  }

  async _onSubmit() {
    const { history, handleSavePPDBOffline } = this.props;
    const { form } = this.state;
    const { value } = form;

    const error = validateRequiredFields(form.value, [
      "father_name",
      "father_nik",
      "father_religions_id",
      "father_pob",
      "father_dob",
      "father_phone",
      "father_occupations_id",
      "father_salary",
      "father_address",
      "mother_name",
      "mother_nik",
      "mother_religions_id",
      "mother_pob",
      "mother_dob",
      "mother_phone",
      "mother_occupations_id",
      "mother_salary",
      "mother_address",
      // "guardian_name",
      // "guardian_nik",
      // "guardian_pob",
      // "guardian_phone",
      // "guardian_salary",
      // "guardian_address",
    ]);

    if (error.father_nik == undefined && form.value.father_nik.length != 16) {
      error.father_nik = "NIK harus 16 digit";
    }
    if (error.mother_nik == undefined && form.value.mother_nik.length != 16) {
      error.mother_nik = "NIK harus 16 digit";
    }
    // if (error.guardian_nik == undefined && form.value.guardian_nik.length != 16) {
    //   error.guardian_nik = "NIK harus 16 digit";
    // }

    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      await handleSavePPDBOffline(value);
      history.push("/dashboard/kesiswaan/list-pendaftaran-ppdb");
    }
  }

  async onEdit() {
    const { history, handleEditPPDBOffline } = this.props;
    const { form } = this.state;
    const { value } = form;
    const res = await handleEditPPDBOffline(value);
    history.push("/dashboard/kesiswaan/list-pendaftaran-ppdb");
  }

  render() {
    const { param, form, page } = this.state;
    const {
      classes = {},
      handleUploadImageFileStudents,
      location,
    } = this.props;
    const { state = {} } = location;
    const { isEdit = false } = state;
    if (!isEdit) {
      form.value.flow_id = 8;
    }
    
    return (
      <div className="manage-registration">
        <form onSubmit={this._onSubmit}>
          {page === 1 ? (
            <PPDBDataDiri
              form={form}
              param={param}
              onRemoveFile={this.onRemoveFile}
              onChange={this._onFormChange}
              handleUploadImageFileStudents={handleUploadImageFileStudents}
            />
          ) : (
            ""
          )}
          {page === 1 ? (
            <PPDBAlamatForm
              form={form}
              onChange={this._onFormChange}
              param={param}
              isEdit={isEdit}
            />
          ) : (
            ""
          )}
          {page === 2 ? (
            <PPDBOrtuForm
              classes={classes}
              form={form}
              onChange={this._onFormChange}
              param={param}
            />
          ) : (
            ""
          )}
          {page === 2 ? (
            <PPDBOrtuIbuForm
              classes={classes}
              form={form}
              onChange={this._onFormChange}
              param={param}
            />
          ) : (
            ""
          )}
          {page === 2 ? (
            <PPDBOrtuWaliForm
              form={form}
              onChange={this._onFormChange}
              param={param}
            />
          ) : (
            ""
          )}

          {page === 1 && (
            <div className="manage-registration__footer">
              <div className="manage-registration__button-wrapper">
                <Button onClick={this._onClickNext} title="Selanjutnya" />
              </div>
            </div>
          )}
          {page === 2 ? (
            <div className="student-list__header">
              <div className="student-list__button">
                <Button onClick={this._onClickBack} title="Kembali" />
              </div>
              <div className="student-list__button">
                {this.state.id ? (
                  <Button type="button" title="Update" onClick={this.onEdit} />
                ) : (
                  <Button onClick={this._onSubmit} title="Simpan" />
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </form>
      </div>
    );
  }
}
ManageRegistrationPPDB.propTypes = {
  handleSavePPDBOffline: PropTypes.func,
  history: PropTypes.object.isRequired,
  handleGetCitizenships: PropTypes.func,
  handleGetReligions: PropTypes.func,
  handleGetEducations: PropTypes.func,
  handleGetOccupations: PropTypes.func,
  handleGetClasses: PropTypes.func,
  user: PropTypes.object,
};
ManageRegistrationPPDB.defaultProps = {
  handleSavePPDBOffline: noop,
  handleGetCitizenships: noop,
  handleGetReligions: noop,
  handleGetEducations: noop,
  handleGetOccupations: noop,
  handleGetClasses: noop,
  handleGetGenders: noop,
  handleGetBloodTypes: noop,
  handleGetLevels: noop,
  user: null,
};

import { connect } from 'react-redux';
import ResetPassword from '../../views/Settings/ResetPassword.view';
import {getUsernameOptions, getUserGroups} from '../../states/thunks/options.thunk';
import { listResetPassword, resetPassword} from '../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleListResetPassword: (payload, goback) => dispatch(listResetPassword(payload, goback)),
    handleResetPassword: (payload, goback) => dispatch(resetPassword(payload, goback)),
    getUsernameOptions: (payload) => dispatch(getUsernameOptions(payload)),
    getUserGroups: (payload) => dispatch(getUserGroups(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

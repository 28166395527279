import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, map, noop } from "lodash";
import ListForm from "../../../components/ListForm/ListForm.component";
import {
  commaFormatted,
  normalizeAmount,
} from "../../../utils/transformer.util";
import { BUATDATA_DATAKELAS_TABLE_FIELDS } from "../../../constants/Akademi/DataKelas/kelas.constant";
import { Button } from "../../../components/base";
import BuatDataKlsForm from "./components/BuatDataKlsForm.component";
import { data } from "jquery";
import { validateRequiredFields } from "../../../utils/validation.util";

export default class TambahKelas extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._setForm = this._setForm.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    this._getClassesOptions = this._getClassesOptions.bind(this);
    this._getPrmClassesOptions = this._getPrmClassesOptions.bind(this);
    this._getTeacherOptions = this._getTeacherOptions.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getMajorOptions = this._getMajorOptions.bind(this);
    // this._getPrmLevels = this._getPrmLevels.bind(this);

    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    this.state = {
      form: {
        value: {
          organizations_id: organizations_id,
          units_id:school_unit.id,
          levels_id,
        },
        error: {},
      },
      param: {},
    };
  }
  componentDidMount() {
    const {
      location,
      handleGetClasses,
      handleGetLevels,
      getPrmClassesOptions,
      user,
    } = this.props;
    const { state = {} } = location;
    const { school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const { isEdit = false, data } = location.state;
    const { id } = state.data || "";
    const paramTypes = ["classes", "classrooms", "levels"];

    if (isEdit && id) {
      this.setState({ id: id });
      this._setForm(data.id);
    }
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });

    handleGetClasses();
    handleGetLevels();
    this._getPrmClassesOptions({ filters: { units_id: [school_unit.id] } });
    this._getTeacherOptions({units_id: [school_unit.id] });
    this._getClassesOptions({ filters: { levels_id: [levels_id], units_id: [school_unit.id] } });
    this._getMajorOptions({ filters: { units_id: [school_unit.id] } });
    // this._getPrmLevels({ filters: {units_id: [school_unit.id], organizations_id: organizations_id } });
  }

  async _getMajorOptions(filters = {}) {
    const { getMajorOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id, units_id } = school_unit;
    const res = await getMajorOptions(filters);
    filters.units_id = units_id;

    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        major: res,
      },
    }));
  }

  async _getPrmLevels(filters = {}) {
    const { getPrmLevels, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPrmLevels(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        levels: res,
      },
    }));
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user, location } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    const { state } = location;
    const { id } = state.data;

    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
      filters.levels_id = levels_id;
      filters.units_id = school_unit.id;

    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    if (id) {
      const classroom_idx = res.findIndex(i => i.value === id)
      res.splice(classroom_idx, 1)
    }
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getPrmClassesOptions(filters = {}) {
    const { getPrmClassesOptions, user, location } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    const { state = {} } = location;
    const { id } = state.data || "";

    filters.units_id = school_unit.id;

    const res = await getPrmClassesOptions(filters);
    
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_classes: res,
      },
    }));
  }

  async _getTeacherOptions(filters = {}) {
    const { getTeacherOptions, user } = this.props;
    const { school_unit } = user;

    const res = await getTeacherOptions(filters);
    filters.units_id = school_unit.id;
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        teacher: res,
      },
    }));
  }

  async _getClassesOptions(filters = {}) {
    const { getClassesOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    filters.units_id = school_unit.id;

    const res = await getClassesOptions(filters);
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        classes: res,
      },
    }));
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions } = this.props;
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _setForm(id) {
    const { handleGetClassData } = this.props;
    try {
      const payload = await handleGetClassData({ id });

      this.setState({
        form: {
          value: {
            id: payload.id,
            prm_classes_id: payload.prm_classes_id,
            name: payload.name,
            classes_id: payload.classes_id,
            other_class_name: payload.other_class_name,
            capacity: payload.capacity,
            majors_id: payload.majors_id,
            homeroom_teacher_id: payload.homeroom_teacher_id,
            subtitute_homeroom_teacher_id:
              payload.subtitute_homeroom_teacher_id,
            report_periods: payload.report_periods,
            levels_id: payload.levels_id,
            seq: payload.seq,
          },
          error: {
            // details: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }
  // on formchange untuk form
  _onFormChange(event) {
    const { form, param } = this.state;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    
    if (name == "classes_id") {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            name: param.classes.find((i) => i.value == value) ? param.classes.find((i) => i.value == value).label : "",
          },
        }
      }))
    }

    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getClassesOptions({ filters: { levels_id: [value] } });
        }
      }
    );
  }

  _onClickAdd(data) {
    const { form } = this.state;
    const { plafon } = form.value;

    plafon.push({
      tipePlafon: "",
      // divisi: '',
      nominal: 0,
      //period:'',
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          plafon,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
    console.log(plafon);
  }

  _onDeleteList(idx) {
    this.setState((prevState) => {
      const { plafon } = prevState.form.value;
      const list = prevState.form.value.plafon;

      list.splice(idx, 1);
      return {
        ...prevState,
        plafon: list,
      };
    });
  }

  // on formchange untuk tabel
  onFormChange(event) {
    const { name, value, dataset } = event.target;
    console.log(name);
    const {
      inputArray = false,
      arrayPosition = 0,
      inputType = "text",
    } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newPlafon = [];
      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newPlafon = prevState.form.value.plafon;
        newPlafon[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { plafon: newPlafon }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            form: "",
            [name]: "",
          },
        },
      };
    });
  }

  async onSubmit() {
    const { history, handleSaveClassesData } = this.props;
    const { form, param } = this.state;
    const { value } = form;
    let canSaveA = false;
    const dataForm = form.value;
    const error = validateRequiredFields(form.value, [
      "classes_id",
      "capacity",
      "name",
    ]);
 
    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
   
    if (!isEmpty(param.classrooms)) {
      if (param.classrooms.find((i) => i.label == dataForm.name)) {
        canSaveA = false;
      }
      else if (isEmpty(error)){
        canSaveA = true;
        await handleSaveClassesData(value);
        history.push("/dashboard/pengaturan/kelas");
      }
      else {
        canSaveA = false;
      }
    }
    else {
      canSaveA = true;
      await handleSaveClassesData(value);
      history.push("/dashboard/pengaturan/kelas");
    }
    // if (isEmpty(error)) {
    //   await handleSaveClassesData(value);
    //   history.push("/dashboard/pengaturan/kelas");
    //   }
  
    // if (canSaveA == false) {
    //   alert(
    //     "Harap lengkapi data form. Bagian yang harus diisi ditandai dengan tanda bintang (*)."
    //   );
    //   form.error.name = "Nama Kelas sudah ada."

    // } else {
    //   await handleSaveClassesData(value);
    //   history.push("/dashboard/pengaturan/kelas");
    // }
  
}
  

  async onEdit() {
    const { history, handleEditClassData } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    const dataForm = form.value;
    if (dataForm.levels_id && dataForm.classes_id && dataForm.name && dataForm.capacity) {
      canSaveA = true;
    } else {
      canSaveA = false;
    }
    if (canSaveA == false) {
      alert(
        "Harap lengkapi data form. Bagian yang harus diisi ditandai dengan tanda bintang (*)."
      );
    } else {
      await handleEditClassData(value);
      history.push("/dashboard/pengaturan/kelas");
    }
  }

  deletePlafon(e) {
    var array = [...this.state.people]; // make a separate copy of the array
    var index = array.indexOf(e.target.value);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ people: array });
    }
  }

  render() {
    const { form, param } = this.state;
    const { id } = form.value;
    const { classes = {}, levels = {}, prm_classes = {} } = this.props;

    if (!isEmpty(param.classrooms)) {
      if (param.classrooms.find((i) => i.label == form.value.name)) {
        form.error.name = "Nama Kelas sudah ada."
      }
    }

    return (
      <div className="add-budget">
        <div className="budget__title">
          <h1>Data Kelas</h1>
          <hr></hr>
        </div>

        {/* <form onSubmit={this.onSubmit}> */}
          {/* <ListForm
            form={form}
            formFields={BUATDATA_DATAKELAS_TABLE_FIELDS}
            onFormChange={this._onFormChange}
          /> */}
          <BuatDataKlsForm
            param={param}
            form={form}
            onFormChange={this._onFormChange}
          />

          <div className="add-budget">
            {this.state.id ? (
              <Button type="button" title="Update" onClick={this.onEdit} />
            ) : (
              <Button type="button" title="Simpan" onClick={this.onSubmit} />
            )}
          </div>
        {/* </form> */}
      </div>
    );
  }

  //   render() {
  //     return (
  //         <ul>
  //             {this.props.items.map(item => (
  //                 <li key={item.id}>{item.text}<button onClick={this._handleDelete.bind(this, item.id)}>Delete</button></li>
  //             ))}
  //         </ul>
  //     );
  // }
}
TambahKelas.propTypes = {
  getParamOptions: PropTypes.func,
  handleGetClasses: PropTypes.func,
  getPrmClassesOptions: PropTypes.func,
  handleGetLevels: PropTypes.func,
  history: PropTypes.object.isRequired,
  handleGetClassData: PropTypes.func,
  user: PropTypes.object,
};
TambahKelas.defaultProps = {
  handleGetClasses: noop,
  getParamOptions: noop,
  handleGetLevels: noop,
  handleGetClassData: noop,
  getPrmClassesOptions: noop,
  user: null,
};

import fixturesConfig from './fixtures.config';

const defaults = {
  ENV: 'dev',
  MOCKAPI: false,
  URL: 'https://api.kliksekolah.com/api',
  //URL_2: 'https://stage.staff.kliksekolah.com/api',
  URL_2: 'https://staff.kliksekolah.com/api',
  URL_3: 'https://tax.kliksekolah.com/api',
  PAYMENT: 'https://api.payment.kliksekolah.com',
  API_AKADEMIS: 'https://stage.api.academics.kliksekolah.com',
  subdomain : window.location.hostname.split('.').slice(0, -2).join('.'),
  API_LOCAL: 'https://stage.api.academics.kliksekolah.com',
  API_BRIDGE: 'https://api.bridge.kliksekolah.com',
  fixtures: fixturesConfig,
};

const setEnv = (envKey, value) => {
  defaults[envKey] = value;
  return defaults;
};

export default {
  setEnv,
  ENV: defaults.ENV,
  MOCKAPI: defaults.MOCKAPI,
  URL: defaults.URL,
  URL_2: defaults.URL_2,
  URL_3: defaults.URL_3,
  PAYMENT: defaults.PAYMENT,
  API_BRIDGE: defaults.API_BRIDGE,
  API_AKADEMIS: defaults.API_AKADEMIS,
  API_LOCAL: defaults.API_LOCAL,
  fixtures: defaults.fixtures,
  PASSWORD: defaults.password,
  subdomain: defaults.subdomain,
};


import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, isEqual, capitalize } from "lodash";
import StudentReportFilter from "../../../components/Filter/StudentReport.filter.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import { validateRequiredFields } from "../../../utils/validation.util";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import {
  Button,
  Pagination,
  Input,
  Select,
  InputDate
} from "../../../components/base/index";
import { TAMBAH_PENILAIAN_KOGNITIF_LIST_TABLE_FIELDS } from "../../../constants/Student/student.constant";
import language from "../../../languages";
import { getDivisiDetailListOptions } from "../../../states/thunks/options.thunk";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class TambahPenilaianNilaiKognitif extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._setForm = this._setForm.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this._getSubjectOptions = this._getSubjectOptions.bind(this);
    this._getTeacherSubjectsOptions = this._getTeacherSubjectsOptions.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._getTypeExam = this._getTypeExam.bind(this);
    this.onClickAddUpload = this.onClickAddUpload.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._getClassesOptions = this._getClassesOptions.bind(this);
    this._getPrmLevels = this._getPrmLevels.bind(this);

    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      page: 1,
      list: [],
      selected: {},
      content: initialContent,
      filters: {},
      form: {
        value: {
          levels_id,
          organizations_id: organizations_id,
          score_max: "100",
          score: "100",
          nilaikognitif: [
            {
              students_id: "",
              score_students: "",
              remedial: "",
            },
          ],
        },
        error: {
          classrooms: "",
          nilaikognitif: "",
        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const { location, getLevels, getClasses, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id, units_id } = school_unit;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    const { content } = this.state;
    const paramTypes = ["classes", "classrooms", "levels"];

    console.log(location);
    paramTypes.forEach((type) => {
      // this._getParamOptions(type);
      this._getParamOptionsAcademics(type);
    });
    if (isEdit && id) {
      this.setState({ id: id });
      this._setForm(data.id, { page: 1 });
    }
    // this.handleGetData();
    // this.isMount = true;
    else if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    this._getSubjectOptions({ filters: { units_id: [units_id] } });
    this._getTypeExam();
    this._getTeacherSubjectsOptions();
    this._getPeriodsOptions({units_id: school_unit.id});
    this._getClassesOptions({ filters: { levels_id: [levels_id], units_id: [school_unit.id] } });
    // this._getPrmLevels({ filters: {units_id: [school_unit.id], organizations_id: organizations_id } });
    
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    if ((!isEdit || !id) && !isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _getPrmLevels(filters = {}) {
    const { getPrmLevels, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPrmLevels(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        levels: res,
      },
    }));
  }

  async _getClassesOptions(filters = {}) {
    const { getClassesOptions, user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    filters.levels_id = levels_id;
    filters.units_id = school_unit.id;
    const res = await getClassesOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        classes: res,
      },
    }));
  }

  async _getSubjectOptions(filters = {}) {
    const { getSubjectOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id, units_id } = school_unit;
    const { form } = this.state;
    const { subject_id } = form.value;
    const res = await getSubjectOptions(filters);
    const selectedSubject = res.find((item) => item.value == subject_id);
    filters.units_id = units_id;

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          kkm: selectedSubject ? selectedSubject.attributes.kkm : "",
        },
      },
      param: {
        ...prevState.param,
        subject: res,
      },
    }));
  }

  async _getTypeExam() {
    const { getTypeExam } = this.props;
    const res = await getTypeExam();
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        type_exam: res,
      },
    }));
  }

  async _getTeacherSubjectsOptions(filters = {}) {
    const { getTeacherSubjectsOptions } = this.props;
    const res = await getTeacherSubjectsOptions(filters);
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        teacher_subjects: res,
      },
    }));
  }

  async _getPeriodsOptions(filters = {}) {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions(filters);
    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
          // tahun ajaran sekarang
          this.setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
              period: data.value,
            }
          }
          }))
      }
    });
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async handleGetData(payload = {}) {
    const { handleListAchievement, user } = this.props;
    const { units_id, workingUnit } = user;
    let unit_id = units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }

    const res = await handleListAchievement({ ...payload });

    this.setState({
      list: res,
    });
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.handleGetData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  async _onSearchContent(params = {}) {
    const { form } = this.state;
    const { value } = form;

    try {
      const { handleListStudentCognitiveValue, user } = this.props;
      const { organizations_id, school_unit } = user;
      const result = await handleListStudentCognitiveValue({
        ...params,
        organizations_id: organizations_id,
        units_id: school_unit.id,
        filters: {
          classrooms_id: value.classrooms_id,
          period: value.period,
        },
      });

      // if (this.isMount) {
      if (result) {
        result.forEach((item) => {
          if (!item.remedial) {
            item.remedial = "";
          }
          if (!item.score) {
            item.score = "";
          }
        });
      }
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            nilaikognitif: result,
          },
        },
      }));

      // }
    } catch (err) {
      // if (this.isMount) {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            nilaikognitif: [],
          },
        },
      }));
    }
    // }
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
      // filters.levels_id = levels_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions, user } = this.props;
    const { organizations_id } = user;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  onClickAddUpload() {
    const { history } = this.props;
    history.push(
      "/dashboard/kurikulum/ulangan/tambah-penilaian-nilai-kognitif/upload-nilai-kognitif",
      { isEdit: true }
    );
  }

  async _setForm(id, params = {}) {
    const { handleGetNilaiKognitif, handleListStudentCognitiveValue, user } =
      this.props;
    const { organizations_id, school_unit } = user;
    const { filters, form } = this.state;
    const { value } = form;
    try {
      const payload = await handleGetNilaiKognitif({ id });
      const {classes_id, levels_id} = payload;
      this._getClassesOptions({ filters: { levels_id: [levels_id] }} );
      this._getSubjectOptions({ filters: { classes_id: classes_id }} );
      // this._getSubjectPsikAfekOptions({ filters: { classes_id: [classes_id] } });
      const result = await handleListStudentCognitiveValue({
        ...params,
        organizations_id: organizations_id,
        units_id: school_unit.id,
        filters: {
          subject_id: value.subject_id,
        },
      });
      this.setState({
        form: {
          value: {
            id: payload.id,
            period: payload.period,
            levels_id: payload.levels_id,
            semester: payload.semester,
            type_of_exam: payload.type_of_exam,
            subject_id: payload.subject_id,
            teacher_id: payload.teacher_id,
            materials: payload.materials,
            date_exam: payload.date_exam,
            organizations_id: payload.organizations_id,
            kkm: payload.kkm,
            score_max: payload.score_max,
            score: payload.score,
            classrooms_id: payload.classrooms_id,
            classes_id: payload.classes_id,
            nilaikognitif: map(payload.nilaikognitif, (detail) => ({
              name: detail.name,
              nis: detail.nis,
              no_va: detail.no_va,
              nisn: detail.nisn,
              score_students: detail.score_students,
              remedial: detail.remedial,
              students_id: detail.students_id,
            })),
          },
          error: {
            nilaikognitif: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  _onFormChange(event) {
    const { form } = this.state;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    const { user } = this.props;
    const { organizations_id } = user;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked ? value : "";
          }
          newList = prevState.form.value[fieldName] || [];
          newListError = prevState.form.error[fieldName] || [];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (name === "score_students") {
            if ( value < form.value.kkm){
              newList[arrayPosition]["remedial"] = "remedial";
            }else{
              newList[arrayPosition]["remedial"] = "";
            }
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        // if (name === "levels_id") {
        //   this._getParamOptionsAcademics("classrooms", {
        //     levels_id: value,
        //     organizations_id: organizations_id,
        //   });
        // }
        if (name === "levels_id") {
          this._getClassesOptions({ filters: { levels_id: [value] } });
        }
        if (name === "subject_id") {
          this._getTeacherSubjectsOptions({ filters: { subject_id: value } });

          const param = this.state.param.subject;
          console.log(param);
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                kkm: param.find((i) => i.value == value).attributes.kkm,
              },
            },
          }));
        }
        if (name === "classrooms_id") {
          this._onSearchContent();
        }
        if (name === "classes_id") {
          this._getParamOptionsAcademics("classrooms", {
            classes_id: value,
            organizations_id,
          });
          this._getSubjectOptions({ filters: { classes_id: value } });
        }
      }
    );
  }

  async onSubmit() {
    const { history, handleSaveCognitiveValue } = this.props;
    const { form } = this.state;
    const { value } = form;

    const error = validateRequiredFields(form.value, [
      "semester",
      "classes_id",
      "classrooms_id",
      "type_of_exam",
      "subject_id",
      "teacher_id",
      "materials",
      "date_exam",
      "score",
    ]);

    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));

    if (isEmpty(error)) {
      await handleSaveCognitiveValue(value);
      history.push("/dashboard/kurikulum/ulangan/pengisian-nilai-kognitif");
    }
  }
  async onEdit() {
    const { history, handleEditNilaiKognitif } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    let canSaveB = false;
    const dataTabel = value.pelajaran;
    const dataForm = form.value;
    let a;
    await handleEditNilaiKognitif(value);
    history.push("/dashboard/kurikulum/ulangan/pengisian-nilai-kognitif");
    history.go(0);

    // }
  }

  _renderButtons(data) {
    const { user } = this.props;
    const button = {};

    return (
      <td>
        <div className="table__actions">
          {/* <Button
                  onClick={() => this.onClickEdit(data)}
                  title='Edit'
                /> */}
        </div>
      </td>
    );
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        // if (name === 'levels_id') {
        //   this._getParamOptionsAcademics( 'classrooms', { filters: { levels_id: value, organizations_id: 3 }} );
        // }
        this._onSearchContent();
      }
    );
  }

  render() {
    const { form, page, list, content, param, filters } = this.state;
    const { plafon } = form.value;
    const { nilaikognitif } = form.value;
    const { classrooms_id = "" } = filters;
    const academic_year = [
      { label: "2019", value: "2019" },
      { label: "2020", value: "2020" },
      { label: "2021", value: "2021" },
    ];
    const semester = [
      { label: "Ganjil", value: "ganjil" },
      { label: "Genap", value: "genap" },
    ];

    return (
      <div className="absensi-rekap__custom-form">
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Select
              label="Tahun Ajaran"
              name="period"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              data={param.periods}
              value={form.value.period}
              // error={form.error.period || ''}
            />
          </div>
          <div className="absensi-rekap__custom-form-column__field">
            <Select
              type="unit"
              name="levels_id"
              label="Unit"
              disabled
              placeholder="Pilihan"
              data={param.levels}
              onChange={this._onFormChange}
              value={form.value.levels_id}
              error={form.error.levels_id || ""}
            />
          </div>
        </div>

        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Select
              name="semester"
              label="Semester *"
              placeholder={"Pilihan"}
              onChange={this._onFormChange}
              data={semester}
              value={form.value.semester}
              error={form.error.semester || ""}
            />
          </div>
          <div className="data-pelanggaran__custom-form-row__field">
            <Select
              name="classes_id"
              label="Tingkat Kelas *"
              onChange={this._onFormChange}
              data={param.classes}
              placeholder={"Pilihan"}
              value={form.value.classes_id}
              error={form.error.classes_id || ""}
            />
            <div className="data-pelanggaran__custom-form-row__field"></div>
            <Select
              name="classrooms_id"
              label="Kelas *"
              onChange={this._onFormChange}
              placeholder="Pilih Kelas"
              data={param.classrooms}
              value={form.value.classrooms_id}
              error={form.error.classrooms_id || ""}
            />
          </div>
        </div>
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Select
              type="text"
              name="type_of_exam"
              label="Jenis Ulangan *"
              placeholder={"Pilihan"}
              data={param.type_exam}
              onChange={this._onFormChange}
              value={form.value.type_of_exam}
              error={form.error.type_of_exam || ""}
            />
          </div>

          <div className="absensi-rekap__custom-form-column__field">
            <Select
              name="subject_id"
              label="Mata Pelajaran *"
              onChange={this._onFormChange}
              data={param.subject}
              placeholder="Pilih Matapelajaran"
              value={form.value.subject_id}
              error={form.error.subject_id || ""}
            />
          </div>
        </div>

        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Select
              name="teacher_id"
              label="Nama Guru *"
              placeholder={"Pilihan"}
              data={param.teacher_subjects}
              onChange={this._onFormChange}
              value={form.value.teacher_id}
              error={form.error.teacher_id || ""}
            />
          </div>
          <div className="absensi-rekap__custom-form-column__field">
            <Input
              type="text"
              name="materials"
              label="Materi *"
              placeholder="Pilihan"
              onChange={this._onFormChange}
              value={form.value.materials}
              error={form.error.materials || ""}
            />
          </div>
        </div>
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <InputDate
              type="date"
              name="date_exam"
              label="Tanggal Ulangan *"
              onChange={this._onFormChange}
              value={form.value.date_exam}
              error={form.error.date_exam || ""}
            />
          </div>
          <div className="absensi-rekap__custom-form-column__field">
            <Input
              type="text"
              name="kkm"
              label="KKM"
              placeholder="Pilihan"
              style={{ backgroundColor: "grey" }}
              onChange={this._onFormChange}
              value={form.value.kkm}
            />
          </div>
        </div>
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Input
              type="number"
              name="score_max"
              label="Skor Maks"
              onChange={this._onFormChange}
              value={form.value.score_max}
              style={{ backgroundColor: "grey" }}
            />
          </div>
          <div className="absensi-rekap__custom-form-column__field">
            <Input
              type="text"
              name="score"
              label="Nilai *"
              onChange={this._onFormChange}
              value={form.value.score}
              error={form.error.score || ""}
              style={{ backgroundColor: "grey" }}
            />
          </div>
        </div>

        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {map(
                  TAMBAH_PENILAIAN_KOGNITIF_LIST_TABLE_FIELDS.label,
                  (field, idx) => (
                    <th key={`table_th_${idx}`}>{field}</th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {map(nilaikognitif, (list, idx) => (
                <tr key={`budget_row_${idx}`}>
                  {map(
                    TAMBAH_PENILAIAN_KOGNITIF_LIST_TABLE_FIELDS.value,
                    (field, fieldIdx) => {
                      if (fieldIdx === 0) {
                        return (
                          <td key={`table_index_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>
                            {idx + 1}
                          </td>
                        );
                      }
                      if (field.attribute === "name") {
                        return (
                          <td key={`table_${fieldIdx}_${idx}`} style={{textTransform:"capitalize"}}>
                            {capitalize(list.name)}
                          </td>
                        )
                      }
                      if (field.attribute === "score_students") {
                        return (
                          <td key={`table_${fieldIdx}_${idx}`}>
                            <Input
                              type="number"
                              name="score_students"
                              data-input-array={true}
                              data-array-position={idx}
                              data-field-name="nilaikognitif"
                              onChange={this._onFormChange}
                              value={list.score_students}
                            />{" "}
                          </td>
                          // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                        );
                      }

                      if (field.attribute === "remedial") {
                        return (
                          <td key={`table_${fieldIdx}_${idx}`}>
                            <Input
                              type="checkbox"
                              name="remedial"
                              data-input-array={true}
                              data-array-position={idx}
                              data-field-name="nilaikognitif"
                              onChange={this._onFormChange}
                              checked={list.remedial === field.value}
                              value={field.value}
                            />{" "}
                          </td>
                        );
                      }

                      return (
                        <td
                          className={field.type === "number" ? "nominal" : ""}
                          key={`table_${fieldIdx}_${idx}`}
                        >
                          {formatData(list, field)}
                        </td>
                      );
                    }
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="student-list__header">
          {/* <div className="student-list__button">
          <Button
          title="Upload"
          onClick={this.onClickAddUpload}
        />
          </div> */}
          <div className="student-list__button">
            {/* {this.state.id?
           <Button
           type="button"
           title="Update"
           onClick={this.onEdit}
         />: */}
            <Button type="button" title="Simpan" onClick={this.onSubmit} />
            {/* } */}
          </div>
        </div>
      </div>
    );
  }
}
TambahPenilaianNilaiKognitif.propTypes = {
  handleGetStudent: PropTypes.func,
  handleGetNilaiKognitif: PropTypes.func,
  handleGetclassrooms: PropTypes.func,
  history: PropTypes.object.isRequired,
};
TambahPenilaianNilaiKognitif.defaultProps = {
  handleGetStudent: noop,
  HandleGetclassrooms: noop,
  handleGetNilaiKognitif: noop,
};

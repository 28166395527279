import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop } from "lodash";
import {
  Input,
  Select,
  FileInput,
  InputDate,
} from "../../../components/base/index";

export default class PPDBDataDiri extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onSetForm = this._onSetForm.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this._onRemoveMulti = this._onRemoveMulti.bind(this);
    this.onUpload = this.onUpload.bind(this);
  }

  _onSetForm() {
    const { location } = this.props;
    const { name } = location.state;
    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          name,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  _onFormChange(e) {
    const { onChange } = this.props;
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);

    onChange(e);
  }

  async onUpload(event) {
    const {
      handleUploadImageFileStudents,
      onChange,
    } = this.props;
    const { target } = event;
    const { files, dataset } = target;
    const upload_image = files[0];

    if (upload_image) {
      const filename = upload_image.name;
      const res = await handleUploadImageFileStudents({
        file: upload_image,
      });
      onChange({
        target: {
          name: "file",
          value: JSON.stringify({
            path: res.path,
            url: res.url,
            filename,
          }),
          dataset: {
            ...dataset,
            inputArray: false,
          },
        },
      });
    }
  }

  _onRemoveMulti(selectedList, removedItem) {
    const { onFormChange } = this.props;
    onFormChange({
      target: {
        name: removedItem,
        value: selectedList,
        dataset: {},
      },
    });
  }

  removeFile(event) {
    const { handleFileUpload, onRemoveFile, idx } = this.props;
    const { target } = event;

    onRemoveFile(idx);
  }

  render() {
    const {
      form,
      param,
      onChange
    } = this.props;
    const { value } = form;
    return (
      <div className="manage-registration__custom-form">
        <h1>Data Pribadi Siswa</h1>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="nis"
              label="NIS/NIM"
              onChange={this._onFormChange}
              value={form.value.nis}
              error={form.error.nis || ""}
            />
            <div className="manage-registration__custom-form-row-datadiri">
              <Input
                type="text"
                name="nisn"
                label="NISN *"
                onChange={this._onFormChange}
                value={form.value.nisn || ""}
                error={form.error.nisn || ""}
                isRequired
                maxLength={10}
              />
            </div>
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="fullname"
              label="Nama Lengkap * (sesuai Akte/Ijazah)"
              onChange={this._onFormChange}
              value={form.value.fullname}
              error={form.error.fullname || ""}
              isRequired
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="nickname"
              label="Nama Panggilan"
              onChange={this._onFormChange}
              value={form.value.nickname}
              error={form.error.nickname || ""}
            />
            {/* <div className="manage-registration__custom-form-row-datadiri">
              <FileInput
                data-input-type="file"
                noMargin
                type="file"
                name="upload_image"
                label="Upload File Foto"
                onChange={this.onUpload}
                onRemoveFile={this.removeFile}
                fileName={value.upload_image ? value.upload_image : ""}
                displayName={
                  value.upload_image ? value.upload_image : "default"
                }
              />
            </div> */}
          </div>
          <div className="manage-registration__custom-form-row__field">
            {/* <InputDate
              type="date"
              name="entrance_date"
              label="Tanggal Masuk *"
              onChange={this._onFormChange}
              value={form.value.entrance_date}
              error={form.error.entrance_date || ""}
              isRequired
            /> */}
            <Input
              type="text"
              name="pob"
              label="Tempat Lahir *"
              onChange={this._onFormChange}
              value={form.value.pob}
              error={form.error.pob || ""}
              isRequired
            />
            <div className="manage-registration__custom-form-row-datadiri">
              <InputDate
                type="date"
                name="dob"
                label="Tanggal Lahir *"
                onChange={this._onFormChange}
                value={form.value.dob}
                error={form.error.dob || ""}
                isRequired
              />
            </div>
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="gender_id"
              label="Jenis kelamin *"
              onChange={this._onFormChange}
              value={form.value.gender_id}
              error={form.error.gender_id || ""}
              placeholder={"Pilihan"}
              data={param.genders}
              isRequired
            />
            <div className="manage-registration__custom-form-row-datadiri">
              <Select
                name="blood_type_id"
                label="Golongan darah"
                onChange={this._onFormChange}
                placeholder={"Pilihan"}
                data={param.blood_types}
                value={form.value.blood_type_id}
                error={form.error.blood_type_id || ""}
              />
            </div>
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="number"
              name="height"
              label="Tinggi Badan "
              onChange={this._onFormChange}
              value={form.value.height}
              error={form.error.height || ""}
            />
            <div className="manage-registration__custom-form-row-datadiri">
              <Input
                type="number"
                name="weight"
                label="Berat Badan"
                onChange={this._onFormChange}
                value={form.value.weight}
                error={form.error.weight || ""}
              />
            </div>
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="religion_id"
              label="Agama *"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              value={form.value.religion_id}
              error={form.error.religion_id || ""}
              data={param.religions}
              isRequired
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="citizen_id"
              label="Kewarganegaraan"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              value={form.value.citizen_id}
              error={form.error.citizen_id || ""}
              data={param.citizenships}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="email"
              label="Email"
              onChange={onChange}
              value={form.value.email}
              error={form.error.email || ""}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="number"
              name="hp"
              label="Nomor Telepon"
              onChange={this._onFormChange}
              value={form.value.hp}
              error={form.error.hp || ""}
            />
          </div>
        </div>
      </div>
    );
  }
}
PPDBDataDiri.propTypes = {
  idx: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  list: PropTypes.array.isRequired,
  religions: PropTypes.array,
  citizenships: PropTypes.array,
  genders: PropTypes.array,
  blood_types: PropTypes.array,
};
PPDBDataDiri.defaultProps = {
  onChange: noop,
  religions: [],
  citizenships: [],
  genders: [],
  blood_types: [],
};

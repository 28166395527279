import React, { Component } from "react";
import PropTypes from "prop-types";
import { noop, map, isEmpty, isEqual, capitalize } from "lodash";
import { Button, Input, SearchSelect, Select } from "../../../components/base";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import { NIS_SISWA_SEARCH } from "../../../constants/student.constant";
import { BATAL_SISWA_KELUAR_TABLE } from "../../../constants/student.constant";
import { commaFormatted, formatData, normalizeAmount } from "../../../utils/transformer.util";
import { STUDENT_TABLE_PPDB } from "../../../constants/student.constant";
import { Link } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { validateRequiredFields } from "../../../utils/validation.util";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};
export default class DaftarRiwayatSiswa extends Component {
    constructor(props) {
      super(props);
      this._onFormChange = this._onFormChange.bind(this);
      this._onClickNext = this._onClickNext.bind(this);
      this._onChangeFilter = this._onChangeFilter.bind(this);
      this._onSearchContent = this._onSearchContent.bind(this);
      //this._onAddList = this._onAddList.bind(this);
      this._onClickAdd = this._onClickAdd.bind(this);
      this._getClassesOptions = this._getClassesOptions.bind(this);
      this._handleFormChange = this._handleFormChange.bind(this);
      this._onSearch = this._onSearch.bind(this);
    //   this._studentCount = this._studentCount.bind(this);
      this._onChangePage = this._onChangePage.bind(this);
      this.onClickCetak = this.onClickCetak.bind(this);
      this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
      // this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
      this.onClickEdit = this.onClickEdit.bind(this);
      this._onClickExcel = this._onClickExcel.bind(this);
      this._getPrmLevels = this._getPrmLevels.bind(this);
  
      const { user } = this.props;
      const { school_unit } = user;
      const { units_id, levels_id } = school_unit;
      const this_year = new Date().getFullYear();

      const entrance = []
      for(var i=2020; i<=this_year+1; i++) {
        var value = i.toString().substring(2)
        entrance.push({
          label: i,
          value: value,
        });
      }

      this.state = {
        page: 1,
        selected: {},
        content: initialContent,
        unchecked: {},
        filters: {},
        list: [],
        form: {
          value: {
            value_year: this_year.toString().substring(2),
            total_siswa: "",
            classes: [],
            // levels_id,
            listsiswa: [],
            classes_id: "",
          },
          error: {
            classes: "",
            levels: "",
            listsiswa: "",
          },
        },
        param: {
          entrance,
        },
        filtered: false,
        total_siswa: "",
      };
    }
  
    componentDidMount() {
      const {
        handleGetCodeOfAccount,
        location,
        handleGetClasses,
        handleGetLevels,
        user,
      } = this.props;
      const { school_unit, organizations_id } = user;
      const { levels_id, units_id } = school_unit;
      const paramTypes = ["classes", "classrooms"];
      const codeTypes = ["kesiswaan", "ppdb_smapldonbosko", "ppdb_smppl1yogya"]
      const { content } = this.state;
      paramTypes.forEach((type) => {
        this._getParamOptionsAcademics(type);
      });
      // codeTypes.forEach((type) => {
        if (isEmpty(content.list)) {
          this._onSearchContent({ page: 1});
        }
      // })
      
      // this._getPeriodsOptions();
    //   this._studentCount();
      this._getClassesOptions({ filters: { levels_id: [levels_id], units_id: [school_unit.id] } });
      this._getPrmLevels({ filters: {units_id: [school_unit.id], organizations_id: organizations_id } });
      
    }
  
    componentDidUpdate(prevProps) {
      // const { user } = this.props;
      // const { workingUnit } = user;
      // const { user: prevUser } = prevProps;
      // const { workingUnit: prevWorkingUnit } = prevUser;
  
      // if (!isEqual(workingUnit, prevWorkingUnit)) {
      //   this.handleGetData();
      // }
      if (!isEqual(prevProps, this.props)) {
        this._onSearchContent({ page: 1 });
      }
    }

    async _getPrmLevels(filters = {}) {
      const { getPrmLevels, user } = this.props;
      const { organizations_id, school_unit } = user;
      const { units_id, levels_id } = school_unit;
      const res = await getPrmLevels(filters);
      filters.organizations_id = organizations_id;
  
      this.setState((prevState) => ({
        ...prevState,
        param: {
          ...prevState.param,
          levels: res,
        },
      }));
    }
  
    onClickCetak() {
      const { history } = this.props;
      history.push("/dashboard/kesiswaan/laporan/daftar-siswa-kelas-unit", {
        isEdit: true,
      });
    }
  
    _onClickExcel() {
      const { history } = this.props;
      history.push('/dashboard/kesiswaan/laporan/daftar-siswa-ppdb/cetak-excel');
    }
  
    async _getParamOptionsAcademics(type, filters = {}) {
      const { getParamOptionsAcademics, user } = this.props;
      const { school_unit, organizations_id } = user;
      if (type === "classrooms" || type === "classrooms") {
        filters.organizations_id = organizations_id;
        filters.units_id = school_unit.id;
      } else {
        delete filters.organizations_id;
      }
      const res = await getParamOptionsAcademics(filters, type);
      this.setState((prevState) => ({
        ...prevState,
        param: {
          ...prevState.param,
          [type]: res,
        },
      }));
    }
    // on formchange untuk form
    _handleFormChange(event) {
      const { name, value } = event.target;
      this.setState((prevState) => ({
        form: {
          value: {
            ...prevState.form.value,
            [name]: value,
          },
          error: {
            ...prevState.form.error,
            [name]: "",
          },
        },
      }));
    }
  
    _onSearch() {
      const { form } = this.state;
      const error = validateRequiredFields(form.value, ["year"]);
      this.setState((prevState) => ({
        form: {
          value: prevState.form.value,
          error,
        },
      }));
      if (isEmpty(error)) {
        this.setState({ filtered: true });
      }
    }
  
    async _getParamOptions(type, filters = {}) {
      const { getParamOptions, user } = this.props;
      const { organizations_id } = user;
      if (type === "classes" || type === "classrooms") {
        filters.organizations_id = organizations_id;
      } else {
        delete filters.organizations_id;
      }
      const res = await getParamOptions(filters, type);
      this.setState((prevState) => ({
        ...prevState,
        param: {
          ...prevState.param,
          [type]: res,
        },
      }));
    }
  
    async _getClassesOptions(filters = {}) {
      const { getClassesOptions, user } = this.props;
      const { school_unit } = user;
      const { levels_id } = school_unit;
      filters.levels_id = levels_id;
      filters.units_id = school_unit.id;
  
      const res = await getClassesOptions(filters);
      // console.log(res)
      this.setState((prevState) => ({
        ...prevState,
        param: {
          ...prevState.param,
          classes_prm: res,
        },
      }));
    }
  
    // async _getPeriodsOptions(filters = {}) {
    //   const { getPeriodsOptions, user } = this.props;
    //   const { organizations_id, school_unit } = user;
    //   const { units_id, levels_id } = school_unit;
    //   const res = await getPeriodsOptions(filters);
    //   filters.organizations_id = 3;
  
    //   this.setState((prevState) => ({
    //     ...prevState,
    //     param: {
    //       ...prevState.param,
    //       periods: res,
    //     },
    //   }));
    // }
  
    // async _studentCount(payload = {}) {
    //   const { handleStudentCount, user } = this.props;
    //   const { organizations_id, school_unit } = user;
    //   const { units_id, levels_id } = school_unit;
    //   const res = await handleStudentCount({
    //     ...payload,
    //     organizations_id: 3,
    //     units_id: school_unit.id,
    //   });
    //   // filters.organizations_id = organizations_id;
    //   // filters.units_id = units_id;
  
    //   this.setState((prevState) => ({
    //     ...prevState,
    //     total_siswa: res,
    //   }));
    // }
  
    _onFormChange(event) {
      const { user } = this.props;
      const { organizations_id } = user;
      const { name, value, dataset, checked, type } = event.target;
      const {
        inputType = "text",
        inputArray = false,
        arrayPosition = 0,
        fieldName,
      } = dataset;
      this.setState(
        (prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;
  
          if (inputType === "number") {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === "checkbox") {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === "code_of_account") {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = "";
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
              },
            },
          };
        },
        () => {
          if (name === "levels_id") {
            this._getClassesOptions({ filters: { levels_id: [value] } });
          }
          if (name === "classes_id") {
            this._getParamOptionsAcademics("classrooms", {
              classes_id: value,
              organizations_id: organizations_id,
            });
          }
        }
      );
    }
  
    _onClickAdd(data) {
      const { form } = this.state;
      const { plafon } = form.value;
  
      plafon.push({
        tipePlafon: "",
        // divisi: '',
        nominal: 0,
        //period:'',
      });
  
      this.setState((prevState) => ({
        form: {
          value: {
            ...prevState.form.value,
            plafon,
          },
          error: {
            ...prevState.form.error,
          },
        },
      }));
    }
  
    async _onSearchContent(params = {}, type) {
      const { filters, form } = this.state;
      const { value } = form;
      const { subject_id, value_year } = value;
      const { year, jenis } = filters;
      const { user } = this.props;
      const { organizations_id } = user;
      this.setState(
        {
          content: initialContent,
        },
        async () => {
          try {
            const {
              handleListStudentPPDB,
              handleListStudentPPDBGeneral,
              handleListReportStudent,
              handleListStudentsPPDBUppRicci,
              handleListAchievement,
              user,
            } = this.props;
            const { school_unit, workingUnit, id, user_group, organizations_id } =
              user;
            const { levels_id, units_id, subdomain } = school_unit;
            //untuk dapat 2 digit terakhir
            let result = {};
            if(school_unit.ppdb_online == 1 && subdomain == null && organizations_id == 3) {
             result =  await handleListStudentPPDB({
                ...params,
                organizations_id: organizations_id,
                units_id: school_unit.id,
                filters: {
                  year: year ? year : value_year
                },
                type
              })
            }
            else if (school_unit.ppdb_online == 1 && subdomain !== null) {
             result = await handleListStudentPPDBGeneral({
                ...params,
                subdomain: subdomain,
                filters: {
                  year: year ? year : value_year
                },
              })
            }
            else if (school_unit.ppdb_online == 1 && subdomain == null && organizations_id == 4 || organizations_id == 5){
             result = await handleListStudentsPPDBUppRicci({
                ...params,
                organizations_id: organizations_id,
                units_id: school_unit.id,
                filters: {
                  year: year ? year : value_year
                },
                type
              })
            }
           
            // }
            this.setState((prevState) => ({
              ...prevState,
              form: {
                ...prevState.form,
                value: {
                  ...prevState.form.value,
                  listsiswa: result,
                },
              },
            }));
          } catch (err) {
            // if (this.isMount) {
            this.setState((prevState) => ({
              ...prevState,
              form: {
                ...prevState.form,
                value: {
                  ...prevState.form.value,
                  listsiswa: [],
                },
              },
            }));
          }
          // }
        }
      );
    }
  
    // on formchange untuk tabel
    onFormChange(event) {
      const { name, value, dataset } = event.target;
      const {
        inputArray = false,
        arrayPosition = 0,
        inputType = "text",
      } = dataset;
      this.setState((prevState) => {
        let formattedValue = value;
        let newPlafon = [];
        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
  
        if (inputArray) {
          newPlafon = prevState.form.value.plafon;
          newPlafon[arrayPosition][name] = value;
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { plafon: newPlafon }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              form: "",
              [name]: "",
            },
          },
        };
      });
    }
  
    _onChangeFilter(e) {
      const { target } = e;
      const { value, name } = target;
      this.setState(
        (prevState) => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            [name]: value,
          },
        }),
        () => this._onSearchContent()
      );
    }
    _onChangePage(page) {
      const { listAmount, keywords } = this.state;
      const offset = listAmount * (page - 1);
      // this.handleGetData({
      this._onSearchContent({
        limit: listAmount,
        keywords,
        offset,
        page,
      });
    }
  
    _onClickNext() {
      window.scrollTo(0, 0);
      this.setState((prevState) => ({
        page: prevState.page + 1,
      }));
    }
  
    onClickEdit(val) {
      const { history } = this.props;
      history.push("/dashboard/kesiswaan/pendaftaran", {
        data: val,
        isEdit: true,
      });
    }

    renderUPPBayar(listsiswa){
      const row = [];
      let totalUPP = 0;
        map(listsiswa.UPPTotal, (data, idx) => {
          totalUPP+=Number(data.nominal)
        })
        row.push([<td>Rp. {commaFormatted(totalUPP)}</td>])
   
      return row;
    }

    renderTotalUPPAwal(listsiswa){
      const row = [];
      let totalUPPAwal = 0;
      map(listsiswa, (list) => {
        map(list.UPPAwal, (data, idx) => {
          totalUPPAwal+=Number(data.nominal)
        })
      })
        row.push([<td>Rp. {commaFormatted(totalUPPAwal)}</td>])
      return row;
    }

    renderTotalUPPBayar(listsiswa){
      const row = [];
      let totalUPPBayar = 0;
      let totalUPP = 0;
      map(listsiswa, (list) => {
        totalUPP = 0;
        map(list.UPPTotal, (data, idx) => {
          totalUPP+=Number(data.nominal)
        })
        totalUPPBayar+=totalUPP
      })
        row.push([<td>Rp. {commaFormatted(totalUPPBayar)}</td>])
      return row;
    }
  
    _renderButtons(data) {
      const { user } = this.props;
      const { user_group } = user;
      const button = {};
  
      return (
        <td>
          <div className="table__actions">
            <Button onClick={() => this.onClickEdit(data)} title="Edit" />
          </div>
        </td>
      );
    }
  
    render() {
      const {
        param,
        form,
        page,
        list = {},
        selected,
        checkedAll,
        content,
        filters,
        listAmount,
        total_siswa,
      } = this.state;
      const { entrance } = param;
      const { listsiswa, value_year } = form.value;
      const { classrooms_id = "", status_id = "", period = "" , year = ""} = filters;
      // console.log(total_siswa);
      const { classes = {}, levels = {}, user } = this.props;
      const { school_unit } = user;
      const presence_status = [
        { label: "Mengikuti", value: "mengikuti" },
        { label: "Tidak Mengikuti", value: "tidak" },
      ];
      const status = [
        { label: "Hadir", value: "HADIR" },
        { label: "Sakit", value: "SAKIT" },
        { label: "Izin", value: "IZIN" },
        { label: "Alpha", value: "ALPHA" },
      ];

      let TotalSeluruhUPPTotal = 0;
      // {map(listsiswa, (list, idx) => (
      //   map(list.UPPTotal, (data, idx) => (
      //     TotalSeluruhUPPTotal+=Number(data.nominal)
      //     ))
      // ))}
      let totalUPP = 0;
      // console.log(totalUPP)
      // const entrance = [
      //   { label: "2020", value: "20" },
      //   { label: "2021", value: "21" },
      //   { label: "2022", value: "22" },
      // ];

      const componentRef = React.createRef();
      return (
        <div className="manage-registration">
           <div className="isi-bidang__custom-form-column">
        <div className="isi-bidang__custom-form-row__field">
        <Select
            name="year"
            label="Tahun Masuk"
            placeholder={'Pilihan'}
            onChange={this._onChangeFilter}
            data={entrance}
            // disabled
            value={year?year:value_year}
          />
          
          <div className="isi-bidang__custom-form-row__field"></div>
        
         </div>
       
           
      </div>
          <div className="beginning-balance__button-wrapper">
            <ReactToPrint
              trigger={() => (
                  <Button title="Cetak" />
              )}
              content={() => componentRef.current}
            />
            <Button
                title="Ekspor Excel"
                onClick={this._onClickExcel}
              />
            </div>
            <form
              ref={componentRef}
            >
            <div className="manage-registration">
              <br></br>
                <h1>Daftar Siswa Jalur PPDB</h1>
                  {/* <h1>Total Siswa {school_unit.name} : {total_siswa}</h1> */}
                  <div className="fund-request__content">
                    <table className="table" ref={componentRef}>
                      <thead>
                        <tr>
                          {map(STUDENT_TABLE_PPDB.label, (field, idx) => (
                            <th key={`table_th_${idx}`}>{field}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {map(listsiswa, (list, idx) => (
                          //fungsi () gunanya agar bisa jalan
                          <>
                        {(() => {
                          totalUPP = 0;
                        })()}

                          <tr key={`budget_row_${idx}`}>
                            <td style={{textAlign:'center'}}>{idx+1}</td>
                            <td style={{textAlign:'center'}}>{list.no_va}</td>
                            {/* <td>{capitalize(list.fullname)}</td> */}
                            <td style={{textTransform: "capitalize"}}>{capitalize(list.fullname)}</td>
                            <td>{list.genders}</td>
                            <td>{list.dob}</td>
                            {list.UPPAwal!=''?
                           (map(list.UPPAwal, (data, idx) => (
                              <td>Rp. {commaFormatted(data.nominal)}</td>
                            ))) : (<td>Rp. 0</td>)}

                            {list.UPPTotal!=''?
                           this.renderUPPBayar(list)
                            : (<td>Rp. 0</td>)}
                            
                       
                          </tr>
                          </>
                        ))}
                        <tr>
                          <td colSpan={5}> Total </td>
                         {this.renderTotalUPPAwal(listsiswa)}
                         {this.renderTotalUPPBayar(listsiswa)}
                          {/* <td>{TotalSeluruhUPPTotal}</td> */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </form>
            </div>
      );
    }
  }
DaftarRiwayatSiswa.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
DaftarRiwayatSiswa.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};

import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, isEqual, map, capitalize } from "lodash";
import { validateRequiredFields } from "../../utils/validation.util";
import { normalizeAmount, formatData } from "../../utils/transformer.util";
import {
  Button,
  Select,
  Pagination,
  SearchSelect,
  Input,
} from "../../components/base/index";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);

export default class BlokUnit extends Component {
  constructor(props) {
    super(props);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  
    const { user } = this.props;
    const { school_unit } = user;
    this.state = {
      page: 1,
      filters: {},
      form: {
        value: {
          listunits: [],
        },
        error: {},
      },
      param: {},
    };
  }

  componentDidMount() {
    this._onSearchContent();
    this._getSchoolUnitsOptions();
  }

  async _getSchoolUnitsOptions(filters = {}) {
    const { getSchoolUnitsOptions } = this.props;
    const { form } = this.state;
    const { units_id } = form.value;
    const res = await getSchoolUnitsOptions(filters);
    const selectedUnits = res.find((item) => item.value == units_id);

    // console.log(units_id)
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          levels_units: selectedUnits ? selectedUnits.attributes.levels_id : "",
        },
      },
      param: {
        ...prevState.param,
        units: res,
      },
    }));
  }
  // componentDidUpdate(prevProps) {
  //   // const { user } = this.props;
  //   // const { workingUnit } = user;
  //   // const { user: prevUser } = prevProps;
  //   // const { workingUnit: prevWorkingUnit } = prevUser;

  //   // if (!isEqual(workingUnit, prevWorkingUnit)) {
  //   //   this.handleGetData();
  //   // }
  //   if (!isEqual(prevProps, this.props)) {
  //     this._onSearchContent({ page: 1 });
  //   }
  // }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    if (name === "user_groups_id") {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          username: "",
        },
      }));
    }
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        this._onSearchContent();
      }
    );
  }

  async _onSearchContent(params = {}) {
    const { filters, param } = this.state;
    let savedFilters = JSON.parse(window.localStorage.getItem("param")) || "";
    const prm_usergroups = savedFilters.userGroups;
    const userStudents = prm_usergroups
      ? prm_usergroups.find((e) => e.label == "student")
      : "";

    if (filters.user_groups_id === "") {
      delete filters.user_groups_id;
    }
    if (
      filters.username === "" ||
      (!isEmpty(filters.username) && filters.username.value === "")
    ) {
      delete filters.username;
    }

    this.setState(async () => {
      try {
        const { handleListBlockUnits, user } = this.props;
        const { school_unit, organizations_id } = user;
        const result = await handleListBlockUnits({
          organizations_id: organizations_id,
          units_id: school_unit.id,
          filters,
        });
        this.setState((prevState) => ({
          ...prevState,
          form: {
            ...prevState.form,
            value: {
              ...prevState.form.value,
              listunits: result,
            },
          },
        }));
      } catch (err) {
        // if (this.isMount) {
        this.setState((prevState) => ({
          ...prevState,
          form: {
            ...prevState.form,
            value: {
              ...prevState.form.value,
              listunits: [],
            },
          },
        }));
      }
      // }
    });
  }

  async onSubmit() {
    const { history, handleSaveBlockUnits } = this.props;
    const { form } = this.state;
    const { value } = form;
    await handleSaveBlockUnits(value);
    history.push(
        "/dashboard/pengaturan/blok-unit"
    );
  }

  _setIsBlocked(isBlocked) {
    const { form, filters, param } = this.state;
    const { value } = form;
    const { listunits, classes_id } = value;
    const newList = JSON.parse(JSON.stringify(listunits));
    newList.forEach((item) => {
      item.is_blocked = isBlocked;
      // if (classroomsId == "lulus" || classroomsId == "naik") {
    //   if (classroomsId !== filters.classrooms_id2) {
    //     item.classes_id = classes_id;
    //   } else {
    //     const prm = param.classrooms_next;
    //     if(filters.classrooms_id2) {
    //       item.classes_id = prm.find(i => i.value == filters.classrooms_id2).prev_classes_id
    //     }
    //   }
    });

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          listunits: newList,
        },
      },
    }));
  }


  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    const { user } = this.props;
    const { organizations_id } = user;

    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName] || [];
          newListError = prevState.form.error[fieldName] || [];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            levels_id: value,
            organizations_id: organizations_id,
          });
        }
        // if (name === 'classes_id') {
        //   this._getParamOptionsAcademics( 'classrooms', {classes_id: value, organizations_id });
        // }
        if (name === "is_blocked" && !inputArray) {
          this._setIsBlocked(value);
        }
        if (name === "levels_id") {
          this._getClassesOptions({ filters: { levels_id: [value] } });
        }
        if (name === 'classes_id') {
          const param = this.state.param.classes_prm;
          this.setState(prevState => ({
                ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                // graduate: param.find(i => i.value == value).attributes.graduate, 
              }
            },
          }));
        
        }
      }
    );
  }
  _renderButtons(data) {
    const { user } = this.props;

    return (
      <td>
        <div className="table__actions">
          <Button onClick={() => this.onReset(data)} title="Reset" />
        </div>
      </td>
    );
  }

  render() {
    const { form, filters, param } = this.state;
    const { listunits } = form.value;
    const prm_usergroups = param.userGroups;
    const prm_block = [
      { value: 0, label: "Open All" },
      { value: 1, label: "Block All" },
    ];

    const prm_is_block = [
      { value: 0, label: "Open" },
      { value: 1, label: "Block" },
    ];

    const userStudents = prm_usergroups
      ? prm_usergroups.find((e) => e.label == "student")
      : "";
  
    return (
      <div className="manage-registration">
        <h1>Blok Unit</h1>
        <hr></hr>
        <div className="absensi-rekap__custom-form">
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <Select
                name="is_blocked"
                label="Pilih Untuk Semua Unit"
                onChange={this._onFormChange}
                data={prm_block}
                placeholder={"Pilihan"}
                value={form.value.is_blocked}
              />
            </div>
            <div className="absensi-rekap__custom-form-column__field">
              <SearchSelect
                noMargin
                async={false}
                name="units_id"
                list={param.units}
                inputArray
                onClick={this._onChangeFilter}
                placeholder="Pilihan"
                value={filters.units_id}
                rightIcon="icon-search"
                label="Pilih Unit"
              />
            </div>
          </div>
        </div>
        <div className="manage-registration">
          <div className="fund-request__content">
            <table className="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Kode Unit</th>
                  <th>Nama Unit</th>
                  <th>Open/Block</th>
                </tr>
              </thead>
              <tbody>
                {map(listunits, (list, idx) => (
                  <tr key={`budget_row_${idx}`}>
                    <td style={{ textAlign: "center" }} key={`table_$${idx}`}>
                      {idx + 1}
                    </td>
                    <td key={`table_${idx}`} style={{ textAlign: "center" }}>
                      {list.unit_code}
                    </td>
                    <td
                      key={`table_${idx}`}
                      style={{ textTransform: "capitalize" }}
                    >
                      {capitalize(list.name)}
                    </td>
                    {/* {this._renderButtons(list)} */}
                    <td key={`table__${idx}`}>
                      <Select
                        placeholder="Pilihan"
                        data-input-array={true}
                        data-array-position={idx}
                        data-field-name="listunits"
                        name="is_blocked"
                        value={list.is_blocked}
                        // data={form.value.graduate === 1?[...lulus, ...tidak_lulus]:[...naik, ...tidak_naik]}
                        data={prm_is_block}
                        onChange={this._onFormChange}
                        // disabled={classrooms_id2 ? false : true}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="add-budget">
              <Button type="submit" title="SIMPAN" onClick={this.onSubmit} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
BlokUnit.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
BlokUnit.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};

import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, isEqual, map, capitalize } from "lodash";
import {
  normalizeAmount,
  formatData,
} from "../../../../utils/transformer.util";
import {
  Button,
  Select,
} from "../../../../components/base";
import { CREATE_INVOICE_TABLE_FIELD_LIST } from '../../../../constants/finance/spp/create_invoice.constant';
import { Link } from "react-router-dom";
import { errorAlert } from '../../../../utils/alert.util';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

// import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import Typography from '@material-ui/core/Typography';

const MySwal = withReactContent(Swal);

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class CreateInvoice extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this.onClickTutupNotif = this.onClickTutupNotif.bind(this);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickRutin = this.onClickRutin.bind(this);
    this.onClickTidakRutin = this.onClickTidakRutin.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    // this._getPrmLevels = this._getPrmLevels.bind(this);
    this._getClassesOptions = this._getClassesOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._initializeData = this._initializeData.bind(this);

    const { user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          total_siswa: "",
          classes: [],
          levels_id,
          listsiswa: [],
          classes_id: "",
          user_id:user.id
        },
        error: {
          classes: "",
          levels: "",
          listsiswa: "",
        },
      },
      param: {},
      total_siswa: "",
      filtered: false,
    };
  }

  componentDidMount() {
    const {
      user,
    } = this.props;
    const { organizations_id } = user;
    const { content, form, filters } = this.state;
    let savedForm = JSON.parse(window.localStorage.getItem('invoiceForm')) || "";
    let savedFilters = JSON.parse(window.localStorage.getItem('invoiceFilters')) || filters;
    if (savedForm != "" && user.id != savedForm.value.user_id) {
      savedForm = "";
      savedFilters = filters;
    }
    // if (isEmpty(content.list)) {
    //   this._onSearchContent({ page: 1 });
    // }
    this._initializeData(savedFilters);
    // this._getPrmLevels({
    //   filters: { units_id: [school_unit.id], organizations_id: 3 },
    // });

    if(savedForm != "") {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            levels_id: savedForm.value.levels_id,
            classes_id: savedForm.value.classes_id,
          },
        },
      }));
      this._getClassesOptions({ filters: { levels_id: [savedForm.value.levels_id] }} );
      this._getParamOptionsAcademics("classrooms", {
        classes_id: savedForm.value.classes_id,
        organizations_id,
      });
    }

    // this.setState({form: savedForm});
  }

  async _initializeData(savedFilters = {}) {
    const {
      user,
    } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    const paramTypes = ["levels"];
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });

    this._getClassesOptions({
      filters: { levels_id: [levels_id], units_id: [school_unit.id] },
    });
    await this._getPeriodsOptions({units_id: school_unit.id});
    
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        classrooms_id : savedFilters.classrooms_id,
      }
    }), () => { 
      // commonActions.setLoading({ show: true });
      this._onSearchContent() 
    });
  }

  _onFormChange(event) {
    const { user } = this.props;
    const { school_unit, organizations_id } = user;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === 'levels_id') {
          this._getClassesOptions({ filters: { levels_id: [value] }} );
        }
        if (name === "classes_id") {
          this._getParamOptionsAcademics("classrooms", {
            classes_id: value,
            organizations_id,
          });
        }
      }
    );
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        window.localStorage.setItem('invoiceForm', JSON.stringify(this.state.form));
        window.localStorage.setItem('invoiceFilters', JSON.stringify(this.state.filters));
        this._onSearchContent()
      }
    );
  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/tagihan-siswa/create-invoice/perkelas');
  }

  // onClickModal(val) {
  //   MySwal.fire({
  //     title: <p>Tambah Invoice</p>,
  //     width: "100%",
  //     html: this.renderModalContent(),
  //     showCancelButton: false,
  //     showConfirmButton: false,
  //   });
  // }

  onClickRutin(val) {
    const { history } = this.props;
    if(val.no_va == "" || val.no_va == null){
      errorAlert({
        title: 'No VA belum tersedia.',
        errorMessage: 'Mohon masukkan nomor pendaftaran di Data Siswa'
      });
    }else{
      history.push("/dashboard/tagihan-siswa/create-invoice/rutin", {
        data: val,
        // isEdit: true,
      });
    }
  }

  onClickTidakRutin(val) {
    const { history } = this.props;
    if(val.no_va == "" || val.no_va == null){
      errorAlert({
        title: 'No VA belum tersedia.',
        errorMessage: 'Mohon masukkan nomor pendaftaran di Data Siswa'
      });
    }else{
      history.push("/dashboard/tagihan-siswa/create-invoice/tidak-rutin", {
        data: val,
        // isEdit: true,
      });
    }
  }

  onClickTidakRutinRICCI(val) {
    const { history } = this.props;
    if(val.no_va == "" || val.no_va == null){
      errorAlert({
        title: 'No VA belum tersedia.',
        errorMessage: 'Mohon masukkan nomor pendaftaran di Data Siswa'
      });
    }else{
      history.push("/dashboard/tagihan-siswa/create-invoice/tidak-rutin-general", {
        data: val,
        // isEdit: true,
      });
    }
  }

  async onClickTutupNotif(val) {
    const { handleSaveNotifProcess, user, handleSaveNotifProcessGeneral } = this.props;
    const { organizations_id } = user;
    const res = organizations_id == 3?
    await handleSaveNotifProcess(val):
    await handleSaveNotifProcessGeneral(val)
    if(res){
      this._onSearchContent();
    }
  }


  async _getPeriodsOptions(filters={}) {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions(filters);

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
        // tahun ajaran sekarang
        this.setState(prevState => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            period: data.value,
          }
        }))
      }
    });
    
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _getPrmLevels(filters = {}) {
    const { getPrmLevels, user } = this.props;
    const { organizations_id, school_unit } = user;
    const res = await getPrmLevels(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        levels: res,
      },
    }));
  }

  async _getClassesOptions(filters = {}) {
    const { getClassesOptions, user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    filters.levels_id = levels_id;
    filters.units_id = school_unit.id;
    
    const res = await getClassesOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        classes_prm: res,
      },
    }));
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id } = user;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { value } = form;
    const { subject_id } = value;
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const {
            handleListNotifProcess,
            handleListNotifProcessGeneral,
            handleListStudentInvoice,
          } = this.props;
          const { user } = this.props;
          const { school_unit, organizations_id } = user;

          const notif = organizations_id == 3?
          await handleListNotifProcess({
            ...params,
            organizations_id,
            units_id: school_unit.id,
            create_user_id: user.id,
            filters,
          })
          :
          await handleListNotifProcessGeneral({
            ...params,
            organizations_id,
            units_id: school_unit.id,
            create_user_id: user.id,
            filters,
          })

          const result = await handleListStudentInvoice({
            ...params,
            organizations_id,
            units_id: school_unit.id,
            filters,
          });
          // }
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listnotif: notif,
                listsiswa: result,
              },
            },
          }));
        } catch (err) {
          // if (this.isMount) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listsiswa: [],
              },
            },
          }));
        }
        // }
      }
    );
  }

  _renderButtons(data) {
    const { user } = this.props;
    const { user_group, organizations_id } = user;
    const button = {};

    return (
      <td>
        <div className="table__actions">
          {/* <Button onClick={() => this.onClickModal(data)} title="Tambah Invoice" /> */}
          <Button onClick={() => this.onClickRutin(data)} title= {organizations_id == 3?"SPP":"Rutin"} />
          <Button 
            onClick={() => organizations_id == 3?
            this.onClickTidakRutin(data):this.onClickTidakRutinRICCI(data)} 
            title={organizations_id ==3?"DPP/UPP":"Non Rutin"}
            disabled={ organizations_id == 3 ? false : true}
            />
        </div>
      </td>
    );
  }

  // renderModalContent(val) {
  //   // const { location } = this.props;
  //   // const { state = {} } = location;
  //   // const { isEdit = false, data } = state;
  //   // const { id } = state.data || "";
  //   // const { form, param } = this.state;
  //   // const { tabelpayments } = form.value;
    
  //   // form.value.total = 0;
  //   // {map(tabelpayments, (data, idx) => (
  //   //   form.value.total+=Number(form.value.tabelpayments[idx].nominal)
  //   // ))}

  //   // const { classes } = this.props;
  //   // const { value } = this.state;

  //   return (
  //     <div className="manage-registration">
  //       {/* <div className={classes.root}>
  //         <AppBar position="static">
  //           <Tabs value={value} onChange={this.handleChange}>
  //             <Tab label="Item One" />
  //             <Tab label="Item Two" />
  //           </Tabs>
  //         </AppBar>
  //         {value === 0 && 
  //           <Typography component="div">
  //             Item 1
  //           </Typography>
  //         }
  //         {value === 1 &&
  //           <Typography component="div">
  //             Item 2
  //           </Typography>
  //         }
  //       </div> */}
  //     </div>
  //   );
  // }

  render() {
    const {
      param,
      form,
      filters,
    } = this.state;
    const { listsiswa, listnotif } = form.value;
    const { classrooms_id = "", period = "" } = filters;
    const { user } = this.props;
    const { organizations_id } = user;

    let title = organizations_id == 3 ? "Tambah Invoice SPP per Kelas" : "Tambah Invoice Rutin per Kelas";
    console.log(param.periods)
    // const { classes } = this.props;
    // const { value } = this.state;
    
    var kelas = ""
    var ket = ""
    var color = ""

    return (
      <div className="manage-registration">
        {/* <div className={classes.root}>
          <AppBar position="static">
            <Tabs value={value} onChange={this.handleChange}>
              <Tab label="Item One" />
              <Tab label="Item Two" />
              <Tab label="Item Three" href="#basic-tabs" />
            </Tabs>
          </AppBar>
          {value === 0 && 
            <Typography component="div" style={{ padding: 8 * 3 }}>
              Item 1
            </Typography>
          }
          {value === 1 &&
            <Typography component="div" style={{ padding: 8 * 3 }}>
              Item 2
            </Typography>
          }
          {value === 2 &&
            <Typography component="div" style={{ padding: 8 * 3 }}>
              Item 3
            </Typography>
          }
        </div> */}
        <div className="budget__title">
          <h1>Buat Invoice </h1>
          <hr></hr>
        </div>
        <br></br>

        {(!isEmpty(listnotif)) && (
          <table className="manage-rapbs-plafon__form-table table-invoice" style={{border: "unset", backgroundColor: "#f5f5f5", padding: "15px"}}>
            {map(listnotif, (list, idx) => (
              <tr key={`budget_row_${idx}`}>
                {(() => {
                  kelas = list.classrooms_name
                  if(list.status == 0) {
                    ket = "masih dalam proses"
                    color = "darkblue"
                  }
                  if(list.status == 1) {
                    ket = "berhasil tersimpan"
                    color = "darkgreen"
                  }
                  if(list.status == 2) {
                    ket = "gagal tersimpan"
                    color = "red"
                  }
                })()}
                <td style={{ textAlign: "left", color: color, border: "unset", padding: 0 }}>
                  Invoice {organizations_id == 3?'SPP':'RUTIN'} untuk kelas {kelas} {ket}.
                </td>
                <td width="5%" style={{ textAlign: "right", border: "unset", padding: 0 }}>
                  {(list.status != 0) && (
                    <Button onClick={() => this.onClickTutupNotif(list)} title="Tutup" />
                  )}
                </td>
              </tr>
            ))}
          </table>
        )}
        <div className="student-list__header">
          <div className="student-list__button">
            <Button type="button" title= {title}  onClick={this.onClickAdd} />
          </div>
        </div>
        <br></br>
        
        <div className="absensi-rekap__custom-form">
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <Select
                type="text"
                name="levels_id"
                label="Unit"
                placeholder="Pilih Unit"
                data={param.levels}
                onChange={this._onFormChange}
                value={form.value.levels_id}
                disabled
              />
            </div>
            <div className="absensi-rekap__custom-form-column__field">
              <Select
                name="period"
                label="Tahun Ajaran"
                onChange={this._onChangeFilter}
                data={param.periods}
                placeholder={"Pilihan"}
                value={period}
              />
            </div>
          </div>

          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <Select
                type="text"
                name="classes_id"
                label="Tingkat Kelas"
                placeholder="Pilih Kelas"
                data={param.classes_prm}
                onChange={this._onFormChange}
                value={form.value.classes_id}
                error={form.error.classes_id || ""}
              />
            </div>
            <div className="absensi-rekap__custom-form-column__field">
              {(form.value.classes_id) && (
                <Select
                  type="text"
                  name="classrooms_id"
                  label="Kelas"
                  placeholder="Pilih Kelas"
                  data={param.classrooms}
                  onChange={this._onChangeFilter}
                  value={classrooms_id}
                />
              )}
            </div>
          </div>
        </div>
        <div className="manage-registration">
          <div className="fund-request__content">
            <table className="table">
              <thead>
                <tr>
                  {map(CREATE_INVOICE_TABLE_FIELD_LIST.label, (field, idx) => (
                    <th key={`table_th_${idx}`}>{field}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {map(listsiswa, (list, idx) => (
                  <tr key={`budget_row_${idx}`}>
                    {map(CREATE_INVOICE_TABLE_FIELD_LIST.value, (field, fieldIdx) => {
                      if (fieldIdx === 0) {
                        return (
                          <td key={`table_index_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>
                            {idx + 1}
                          </td>
                        );
                      }
                      if (field.attribute === "students_name") {
                        return (
                          <td key={`table_${fieldIdx}_${idx}`}>
                            <Link
                              to={{
                                pathname:
                                  "/dashboard/kesiswaan/laporan/cetak-buku-induk",
                                state: { ...list },
                              }}
                            >
                              {list.students_name}
                            </Link>
                          </td>
                        );
                      }
                      if (field.attribute === "name") {
                        return (
                          <td key={`table_${fieldIdx}_${idx}`} style={{textTransform:"capitalize"}}>
                            {capitalize(list.name)}
                          </td>
                        )
                      }
                      return (
                        <td
                          className={field.type === "number" ? "nominal" : ""}
                          key={`table_${fieldIdx}_${idx}`}
                        >
                          {formatData(list, field)}
                        </td>
                      );
                    })}
                    {this._renderButtons(list)}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
CreateInvoice.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
CreateInvoice.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};

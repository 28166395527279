import { connect } from 'react-redux';
import LaporanPembayaranPPDB from '../../../../views/Finance/Spp/LaporanPembayaranPPDB/LaporanPembayaranPPDB.view';
import { reportPPDBSpp, reportPPDBUpp, reportPPDBOfflineSpp, reportPPDBOfflineUpp, reportPPDBSppF07,
   reportPPDBUppF07, listStudentsClassDivGeneral, reportPPDBSppGeneral, reportPPDBUppGeneral, reportPPDBSPPRicci, reportPPDBUPPRicci } from '../../../../states/thunks/academics.thunk';
import { getYearsOfEducationsOptions } from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleReportPPDBSpp: (payload, goback) => dispatch(reportPPDBSpp(payload, goback)),
    handleReportPPDBUpp: (payload, goback) => dispatch(reportPPDBUpp(payload, goback)),
    handleReportPPDBSppF07: (payload, goback) => dispatch(reportPPDBSppF07(payload, goback)),
    handleReportPPDBUppF07: (payload, goback) => dispatch(reportPPDBUppF07(payload, goback)),
    handleReportPPDBOfflineSpp: (payload, goback) => dispatch(reportPPDBOfflineSpp(payload, goback)),
    handleReportPPDBOfflineUpp: (payload, goback) => dispatch(reportPPDBOfflineUpp(payload, goback)),
    handleReportPPDBSppGeneral: (payload, goback) => dispatch(reportPPDBSppGeneral(payload, goback)),
    handleReportPPDBUppGeneral: (payload, goback) => dispatch(reportPPDBUppGeneral(payload, goback)),
    getYearsOfEducationsOptions: (payload) => dispatch(getYearsOfEducationsOptions(payload)),
    handleListStudentsClassDivGeneral:(payload, goback) => dispatch(listStudentsClassDivGeneral(payload, goback)),
    handleReportPPDBSppRicci:(payload, goback) => dispatch(reportPPDBSPPRicci(payload, goback)),
    handleReportPPDBUppRicci:(payload, goback) => dispatch(reportPPDBUPPRicci(payload, goback)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LaporanPembayaranPPDB);
